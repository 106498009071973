@font-face {
  font-family: 'W95FA';
  src: url('./assets/W95FA/w95fa.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.app {
  width: 100%;
  height: 100%;
  font-family: 'W95FA', sans-serif!important;
  overflow: hidden; /* Ensures no scrollbars appear */
  background-image: linear-gradient(to bottom, #b1b1b1, 1px, transparent 1px);
  background-size: 100% 3px;
}
