.aboutme-win-window {
    height: 350px;
    width: 350px;
    .aboutme-content {
        padding: 12px;
        margin: 2px 4px;
        width: calc(100% - 32px);
        height: calc(100% - 60px);
    
        .tabs {
            display: flex;
            .tab {
                color: black;
                border: 1px solid rgb(255, 255, 255);
                border-right: none;
                padding: 2px 8px;
                background-color: #c3c3c3;
                background-image: linear-gradient(to bottom, #9e9e9e, 1px, transparent 1px);
                background-size: 100% 3px;
            }     
            .empty-tab {
                width: -webkit-fill-available;
                border-top: none;
                border-right: none;
            }   
        }
        
        .tabs button.active {
            border-bottom: none;
        }
    
        .tabs-content {
            height: calc(100% - 22px);
            margin-bottom: 16px;
            border-left: 1px solid rgb(255, 255, 255);
            border-bottom: 1px solid rgb(44, 44, 44);
            border-right: 1px solid rgb(44, 44, 44);

            .aboutme-hello {
                display: flex;
                flex-direction: column;
                padding: 32px;
                
                .aboutme-name {
                    justify-content: end;
                    display: flex;
                    place-items: self-end;
                    .aboutme-pic {
                        width: 90px;
                        border-radius: 50%;
                        margin-right: 32px;
                    }
                    .aboutme-firstname {
                        display: flex;
                        flex-direction: column;
                        align-items: end;
                        font-size: 38px;
                        letter-spacing: 1px;
                        .aboutme-lastname {
                            margin-top: 8px;
                            font-weight: 700;
                        }
                    }
                    
                }
                .aboutme-info {
                    display: flex;
                    flex-direction: column;
                    place-items: self-end;
                    margin-top: 20px;
                    font-size: 20px;
                    .aboutme-address {
                        margin-top: 4px;
                    }
                }
            }

            .aboutme-skills {
                padding: 44px 32px ;
                .aboutme-skills-title {
                    font-size: 28px;
                }
                .aboutme-skills-desc {
                    font-size: 18px;
                    margin-top: 12px;
                    
                }
            }

            .aboutme-social, .aboutme-uiux {
                padding: 44px 32px;
                display: flex;
                flex-direction: column;
                .aboutme-social-title, .aboutme-uiux-title {
                    font-size: 32px;
                    margin-bottom: 16px;
                }
                a {
                    color: black;
                    font-size: 18px;
                    margin-bottom: 6px;
                }
            }
        }
        
    }
}
@media (max-width: 768px) {
    .aboutme-win-window {
        height: 350px;
        width: 300px;
    }
    .aboutme-skills-desc {
        font-size: 16px!important;
    }
}
@media (max-width: 520px) {
    .aboutme-win-window {
        width: 80%!important;
    }
}