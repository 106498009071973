.start-button, .start-button-pressed {
    width: 90px;
    color: black;
    background-color: #bdbebd;
    border: 1px solid #7f7f7f;
    cursor: pointer;
    font-weight: bold;
    font-family: 'W95FA', sans-serif!important;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 22px;
    background-image: linear-gradient(to bottom, #aaaaaa, 1px, transparent 1px);
    background-size: 100% 3px;
  }

  .start-button {
    border-top: 2px solid rgb(255, 255, 255);
    border-left: 2px solid rgb(255, 255, 255);
    border-bottom: 2px solid rgb(44, 44, 44);
    border-right: 2px solid rgb(44, 44, 44);
  }
  
  .start-button-pressed {
    border-bottom: 2px solid rgb(255, 255, 255);
    border-right: 2px solid rgb(255, 255, 255);
    border-top: 2px solid rgb(44, 44, 44);
    border-left: 2px solid rgb(44, 44, 44);
  }
  
  .win95-icon {
    margin-left: 0;
    width: 26px;
  }

  .start-menu {
    position: absolute;
    bottom: 30px; /* height of the taskbar */
    left: 0;
    width: 200px;
    background-color: #c3c3c3;
    border: 1px solid #7f7f7f;
    z-index: 10;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  }


@media (max-width: 520px) {
    .win95-icon {
      margin-right: 6px;
    }
    .start-button, .start-button-pressed {
      height: 100%;
    }
}