.desktop {
    width: 100vw;
    height: 100vh;
    position: relative;
    background: #18181d;
    .folder-icon, .music-icon, .behance-icon {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 100px;
	  z-index: 11;
      cursor: pointer;
      top: 15px;
      left: 20px;
      img {
        width: 85px;
        opacity: 0.9;
      }
      span {
        color: rgb(182, 182, 182);
        font-size: 16px;
      }
    }
    .music-icon {
      top: 236px;
    }
    .behance-icon {
      top: 126px;
      opacity: 0.8;
      img {
        width: 70px;
      }
    }
    
	.astr, .a-stars, .clouds-container {
		position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
	}
	
	.astr {
		z-index: 3;
		top: 38%;
		animation: AstrfloatAnimation 10s infinite alternate;
		img {
			max-width: 200px;
		}
	}
	.a-stars {
		z-index: 2;
		img {
			width: 30vw;
    		opacity: 0.4;
		}
	}

	.clouds-container {
		top: 86%;
		bottom: 16%;
		width: 60%;
		height: 265px;
		z-index: 3;
		overflow: hidden;
		svg {
			width: 60px;
			position: absolute;
			left: 30%;
			bottom: 5%;
			opacity: 0.6;
		}

		img {
			position: absolute;
			max-width: 300px;
			max-height: 216px;
			animation-timing-function: ease-in-out;
		}
		/* Random positioning for each cloud */
		img:nth-child(1) { top: 5%; left: 15%; animation: moveCloud1 35s infinite}
		img:nth-child(2) { top: 10%; left: 35%; animation: moveCloud2 15s infinite}
		img:nth-child(3) { top: 25%; left: 25%; animation: moveCloud3 38s infinite}
		img:nth-child(4) { top: 22%; left: 5%; animation: moveCloud4 25s infinite }
		img:nth-child(5) { bottom: 20%; left: 60%; animation: moveCloud5 20s infinite}
		img:nth-child(6) { top: 30%; left: 50%; animation: moveCloud6 26s infinite}
		img:nth-child(7) { top: 25%; left: 52%; opacity: 0.8; animation: moveCloud7 18s infinite}
		img:nth-child(8) { top: 25%; left: 52%; opacity: 0.9; animation: moveCloud7 18s infinite}

		.img:nth-child(1)::before,
		.img:nth-child(1)::after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-image: url('../../assets/cloud1-.png'); /* Same as original image */
			clip-path: polygon(0 2%, 100% 2%, 100% 4%, 0 4%); /* Small slice of the image */
			animation: glitchEffect 1s infinite alternate-reverse;
		}

		.img:nth-child(1)::after {
			clip-path: polygon(0 98%, 100% 98%, 100% 100%, 0 100%); /* Another small slice at the bottom */
			animation-delay: 0.25s;
		}
	}
	
}
@media (max-width: 980px) {
    .clouds-container {
        width: 100%!important;
        
    }

}
@media (max-width: 768px) {
    .fourth-img {left: -12%!important;}
    .desktop {position: fixed;}
}
@media (max-width: 520px) {
    .border {top: 70%!important;}
    .astr {top: 45%!important; transform: translate(-50%, -50%)!important;}
    .clouds-container {
      top:78%!important;
      img:nth-child(1) { top: 5%; left: 0%!important; animation: moveCloud1 35s infinite}
      img:nth-child(2) { top: 10%; left: 35%!important; animation: moveCloud2 15s infinite}
      img:nth-child(3) { top: 25%; left: 15%!important; animation: moveCloud3 38s infinite}
      img:nth-child(6) { top: 30%; left: 40%!important; animation: moveCloud6 26s infinite}
      img:nth-child(8) { top: 25%; left: 42%!important; opacity: 0.9; animation: moveCloud7 18s infinite}
    }
    .folder-icon, .music-icon, .behance-icon {
      img {
        width: 65px!important;
      }
    }
    .music-icon {
      display: none!important;
    }
    .behance-icon {
      top: 108px!important;
    }
}

@keyframes floatAnimation {
    0%, 100% {
        transform: translate(-50%, -50%);
    }
    50% {
        transform: translate(-50%, calc(-50% + 7px)); 
    }
}

@keyframes AstrfloatAnimation {
  0%, 100% {
      transform: translate(-50%, -50%);
  }
  50% {
      transform: translate(-50%, calc(-50% + 7px)); 
  }
}

@keyframes starsfloatAnimation {
    0%, 100% {
        transform: translate(-50%, -50%);
    }
    50% {
        transform: translate(-50%, calc(-50% + 5px)); 
    }
}
@media (max-width: 560px) {
    .custom-svg {
    	min-width: 270px!important;
		  top: 72%!important;
    }
}

.stars-contrainer {
	position: absolute;
}

$shootingStarContainerCount: 3;
$starCount: 10;

.shootingStarContainer {
    @for $j from 1 through $shootingStarContainerCount {
        &:nth-child(#{$j}) {
            position: absolute;
            width: 100%;
            height: 130%;
            transform: rotate(random(10)+35deg);
            z-index: 1;
            //    background-color: #30000080;
        }
    }
}

.shootingStar {
    position: absolute;
    height: 7px;
    background: linear-gradient(-70deg, rgb(242, 192, 220), rgba(0, 0, 0, 1));

    @for $j from 1 through $shootingStarContainerCount {
        @for $i from 1 through $starCount {
            .shootingStarContainer:nth-child(#{$j}) &:nth-child(#{$i}) {
                $delay: random(15000) + 200ms;
                $duration: random(2000) + 750ms;
                left: calc(#{random(50) + 0%});
                top: calc(#{random(98) + 1%});
                animation: shootingFrames $duration ease-in-out $delay;
                opacity: random(75) / 100 + 0.15;

            }
        }
    }
}

@keyframes shootingFrames {
    0% {
        transform: translateX(0);
        width: 0;
    }

    30% {
        width: 200px;
    }

    100% {
        width: 0;
        transform: translateX(random(100) + 800px);
    }
}







.stars,
.twinkleMask,
.twinkleMask2,
.clouds {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: block;
}


.twinkleMask {
    z-index: 1;
    animation: twinkleFrames 700s linear infinite;
}

.twinkleMask2 {
    z-index: 2;
    height: 200%;
    animation: twinkleFrames2 300s linear infinite;
}

.clouds {
    z-index: 3;
    height: 150%;
    animation: cloudsFrames 300s linear infinite;
}

.fogContainer {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: relative;
    z-index: 7;
}

.fog {
    position: absolute;
    left: -50%;
    top: -30%;
    width: 200%;
    height: 200%;
    animation-name: fogFrames;
    animation-duration: 60s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    background: linear-gradient(-5deg, #{"#00000000 0%, #ffffff15 40%, #74590121 45%, #8b845b21 55%, #00000000 70%"});
}

.wrapper-stars-2 {
	transform: rotate(90deg);
	opacity: 0.2;
}
.wrapper-stars-1 {
	opacity: 0.5;
}

@keyframes fogFrames {
    0% {
        transform: none;
    }

    10% {
        transform: scaleY(.75) translate(5%, -2%) rotate(15deg);
    }

    25% {
        transform: scaleY(1) translate(10%, 7%);
    }

    50% {
        transform: scaleY(0.5) rotate(-15deg);
    }

    75% {
        transform: scaleY(0.2) translate(7%, -2%);
    }

    100% {
        transform: none;
    }

}

@keyframes twinkleFrames {
    from {
        background-position: 0 0;
    }

    to {
        background-position: -10000px 5000px;
    }
}

@keyframes twinkleFrames2 {
    from {
        background-position: 0 0;
    }

    to {
        background-position: 10000px 5000px;
    }
}

@keyframes cloudsFrames {
    from {
        background-position: 0 0;
    }

    to {
        background-position: 10000px 0;
    }
}


@keyframes glower {
    0% {
      background-position: 0 0;
    }
    
    50% {
      background-position: 400% 0;
    }
    
    100% {
      background-position: 0 0;
    }
  }

@keyframes moveCloud1 {
	0%, 100% {
		transform: translateY(0);
        transform: rotate(45deg)
	  }
	  50% {
		transform: translateY(-4px); // Change the value for a larger or smaller float
        transform: rotate(-25deg)
	  }
}

@keyframes moveCloud2 {
	0%, 100% {
		transform: translateY(0);
	  }
	  50% {
		transform: translateY(-15px); // Change the value for a larger or smaller float
	  }
}

@keyframes moveCloud3 {
	0%, 100% {
		transform: translateY(0);
        transform: rotate(0deg)
	  }
	  50% {
		transform: translateY(-10px); // Change the value for a larger or smaller float
        transform: rotate(30deg)
	  }
}
@keyframes moveCloud4 {
	0%, 100% {
		transform: translateY(0);
        transform: rotate(0deg)
	  }
	  50% {
		transform: translateY(-18px); // Change the value for a larger or smaller float
        transform: rotate(45deg)
	  }
}
@keyframes moveCloud5 {
	0%, 100% {
		transform: translateY(0);
        transform: rotate(0deg)
	  }
	  50% {
		transform: translateY(-8px); // Change the value for a larger or smaller float
        transform: rotate(-10deg)
	  }
}
@keyframes moveCloud6 {
	0%, 100% {
		transform: translateY(0);
	  }
	  50% {
		transform: translateY(-7px); // Change the value for a larger or smaller float
	  }
}
@keyframes moveCloud7 {
	0%, 100% {
		transform: translateY(0);
	  }
	  50% {
		transform: translateY(-18px); // Change the value for a larger or smaller float
	  }
}


:root {
    --border-color: rgba(225, 121, 171, 0.732);
    --border-width: 2px;
    --line-color: rgba(255, 229, 242, 0.921);
    --line-width: 100px;
    --content-background: url('../../assets/stars.png');
    --content-size: 250px;
    --animation-speed: 9s;
  }
  
  @keyframes borderAnimation {
    from {
      transform: rotate(0deg);
    }
    
    to {
      transform: rotate(180deg);
    }
  }
  
  @keyframes neonAnimation {
    from {
      text-shadow: 0 0 5px var(--line-color);
    }
    50% {
      text-shadow: 0 0 10px var(--line-color);
    }
    
    to {
      text-shadow: 0 0 5px var(--line-color);
    }
  }
  
  .border {
    position: absolute;
    top: 68%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
    overflow: hidden;
    background: var(--border-color);
    padding: var(--border-width);
    box-shadow: 0 0 15px var(--border-color);
    font-size: 0;
  }
  
  .gradient {
    position: absolute;
    width: calc(var(--content-size) * 2);
    height: calc(var(--content-size) * 2);
    
    top: calc(50% - var(--content-size));
    left: calc(50% - var(--content-size));
    
    background: rgba(0, 0, 0, 0);
    background: linear-gradient(
      to right, 
      rgba(0, 0, 0, 0) calc(50% - var(--line-width) / 2),
      var(--line-color) 50%,
      rgba(0, 0, 0, 0) calc(50% + var(--line-width) / 2));
    
    animation:
      borderAnimation
      var(--animation-speed)
      infinite
      linear;
  }
  .container,
.container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
  .container {
    position: relative;
    display: inline-block;
    width: var(--content-size);
    height: 70vh;
    
    font-family: sans-serif;
    font-weight: 100;
    font-size: 40px;
    
    color: var(--line-color);
    text-shadow: 0 0 0.5em var(--line-color);
    text-align: center;
    line-height: var(--content-size);
    
    background: var(--content-background);
    background-color: #18181d;
    background-size: cover;
    background-position: -150px -100px;
    animation:
      neonAnimation
      var(--animation-speed)
      infinite
      linear;
  }
  .container::before {
    background-image: url('../../assets/cloud3-.png');
    background-position: -80px -89px; /* Adjust as needed */
    z-index: 1; /* Make it sit between the gradient and the original background image */
    opacity: 0.6;
  }