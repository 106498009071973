.menu {
    display: flex;
    padding-right: 8px;
    height: 300px;
    border: 1px solid #7f7f7f;
    background-color: rgb(175, 175, 175);
    position: absolute;
    bottom: 36px;
    left: 0;
    background-image: linear-gradient(to bottom, #9b9b9b, 1px, transparent 1px);
    background-size: 100% 3px;
    z-index: 99;
    .left-side {
        position: relative;
        width: 33px;
        background-color: #7f7f7f;
        color: #ffffff;
        transform-origin: center;
        margin: 3px;
        padding: 22px 0;
        background: rgb(38,36,83);
        background: linear-gradient(rgb(0, 0, 128), rgb(16, 132, 208));
        p {
            font-size: 26px;
            transform: rotate(-90deg);
            position: absolute;
            right: -31px;
            bottom: 14px;
            span {
                font-weight: 700;

            }
        }
    }

    .right-side {
        display: flex;
        flex-direction: column;

        .menu-item {
            display: flex;
            align-items: center;
            padding: 5px;
            margin: 3px 0;
            width: 115px;
            cursor: pointer;

            .icon {
                max-width: 26px;
                margin-right: 10px;
            }

            &:hover {
                background-color: #7f7f7f;
                color: #c3c3c3;
            }

            a {
                text-decoration: none;
                color: black;
            }
        }
    }
}
.window-container{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
}    