.taskbar {
    height: 36px;
    background-color: #bdbebd;
    background-image: linear-gradient(to bottom, #aaaaaa, 1px, transparent 1px);
    background-size: 100% 3px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
    position: fixed;
    bottom: 0px;
    width: 100vw;
    border-top: 2px solid rgb(255, 255, 255);
    padding: 3px;
    z-index: 15;

    .time-display {
      margin-left: auto; // This will push the time to the right side of the taskbar
      padding: 5px 10px;
      background-color: #c3c3c3;
      border: 1px solid #7f7f7f;
      font-weight: bold;
      display: flex;
      align-items: center;
      border-bottom: 2px solid rgb(255, 255, 255);
      border-right: 2px solid rgb(255, 255, 255);
      border-top: 2px solid rgb(88, 88, 88);
      border-left: 2px solid rgb(88, 88, 88);
      background-image: linear-gradient(to bottom, #aaaaaa, 1px, transparent 1px);
      background-size: 100% 3px;
      font-size: 22px;
      font-weight: 500;
      margin-right: 10px;

      .mute-btn {
        width: 16px;
      }
    }

    .window-tabs {
      display: flex;
      margin-left: 12px;
      .window-tab {
        display: flex;
        justify-content: start;
        align-items: center;
        width: fit-content;
        padding-right: 8px;
        min-width: 100px;
        margin: 0 4px;
        padding-left: 6px;
        border-top: 2px solid rgb(255, 255, 255);
        border-left: 2px solid rgb(255, 255, 255);
        border-bottom: 2px solid rgb(44, 44, 44);
        border-right: 2px solid rgb(44, 44, 44);

        span {
          font-size: 20px;
        }
        .icon {
          max-width: 16px;
          margin-right: 6px;
        }
      }
      .active {
        border-bottom: 2px solid rgb(255, 255, 255);
        border-right: 2px solid rgb(255, 255, 255);
        border-top: 2px solid rgb(44, 44, 44);
        border-left: 2px solid rgb(44, 44, 44);
      }
    }
  }

@media (max-width: 520px) {
    .window-tab {
      min-width: 20px!important;

      span {
        display: none;
      }
    }
}

@media (max-width: 380px) {
  .time-display {
    font-size: 1px!important;
  }
}