.win95-window {
    border: 2px solid #000;
    background-color: #c3c3c3;
    width: 400px;
    height: 300px;
    position: absolute;
    transform: translate(-50%, -50%);
    background-image: linear-gradient(to bottom, #9e9e9e, 1px, transparent 1px);
    background-size: 100% 3px;
}

.title-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(0, 0, 128);
    color: #fff;
    padding: 2px 4px;
    margin: 2px 4px;
    background-image: linear-gradient(to bottom, rgb(9, 9, 87), 1px, transparent 1px);
    background-size: 100% 3px;
    .title {
        font-size: 14px;
        display: flex;
        align-items: center;
    }

    .btns {
        max-height: 14px;
        img {
            padding-left: 2px;
        }
    }

    .title-icon {
        max-width: 14px;
        padding: 0 4px;
    }


    .close-button:hover {
        background-color: #e3e3e3;
    }
}

.content {
    background-color: #272a2d;
    padding: 12px;
    margin: 2px 4px 12px 4px;
    width: calc(100% - 32px);
    height: calc(100% - 60px);
    color: #56d7e4;
    overflow-y: scroll;
    background-image: linear-gradient(to bottom, #232323, 1px, transparent 1px);
    background-size: 100% 3px;
    .window-content {
        color: #54c0cb;
        line-height: 1.2;
        letter-spacing: 1px;
        .window-content-icon {
            padding: 8px;
            float: left;
            margin-right: 6px;
            width: 28px;
        }
        .highlight-rect {
            background-color: #f9ff00;
            color: rgb(0, 0, 0);
            padding: 0px 5px;
            margin-left: -5px;
        }
        .highlight {
            color:#f9ff00;
            text-decoration: underline
        }
    }
}
.content::-webkit-scrollbar {
    width: 12px;
    background-color: #c3c3c3;
    background-image: linear-gradient(to bottom, #b1b1b1, 1px, transparent 1px);
    background-size: 100% 3px;
}
.content::-webkit-scrollbar-thumb {
    background-color: #939393;
}

/* On hover of the scrollbar thumb */
.content::-webkit-scrollbar-thumb:hover {
    background-color: #7e7e7e;
}

@media (max-width: 768px) {
    .win95-window {
        width: 320px;
        height: 300px;
    }
}
@media (max-width: 350px) {
    .win95-window {
        position: absolute; /* or relative, fixed, static */
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%)!important;
        width: 300px;
        height: 300px;
    }
}